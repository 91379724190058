import React from 'react';
import styled from 'styled-components';
import { Grid, GridColumn } from '@atlaskit/page';
import { gridSize } from '@atlaskit/theme';

const Padding = styled.div`
  margin: ${gridSize() * 2}px ${gridSize() * 8}px;
  padding-bottom: ${gridSize() * 3}px;
`;

const ContentWrapper = ({ children, theme }) => (
  <div className={theme}>
    <Grid layout="fluid">
      <GridColumn>
        <Padding>{children}</Padding>
      </GridColumn>
    </Grid>
  </div>
);

export default ContentWrapper;
