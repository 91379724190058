import { createSlice } from '@reduxjs/toolkit';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

import JSONLDLogo from '../../images/json-ld.png';

const NameWrap = styled.div`
  display: flex;
  flex-direction: row;
  a {
    display: inline-block;
    height: 42px;
    line-height: 42px;
  }
  > img {
    box-sizing: content-box;
    height: 24px;
    width: 24px;
    padding: 9px;
  }
`;

export const schemasSlice = createSlice({
  name: 'schemas',
  initialState: {
    loading: true,
    value: [],
    current: {},
  },
  reducers: {
    setSchemas: (state, action) => {
      state.value = action.payload.schemas;
      state.loading = false;
    },
    setCurrent: (state, action) => {
      state.current = action.payload.current;
    }
  },
});

const { setCurrent, setSchemas } = schemasSlice.actions;

function NameCell({ name }) {

  return (
    <NameWrap>
      <img src={JSONLDLogo} alt={name} />
      <Link to={`/schemas/${name}`}>{name}</Link>
    </NameWrap>
  );
}

export const fetchSchemasAsync = () => async dispatch => {
  try {
    const response = await axios.get('/api/schemas');
    const schemas = response.data.map((name, i) => ({
      key: `row-${i}`,
      cells: [
        {
          key: createKey(name),
          content: (
            <NameCell name={name} />
          ),
        },
      ],
    }));
    dispatch(setSchemas({ schemas }));
  } catch (err) {
    console.error(err);
  }
}

export const fetchSchemaAsync = (name) => async dispatch => {
  try {
    const response = await axios.get(`/api/schemas/${name}`);
    dispatch(setCurrent({ current: response.data }));
  } catch (err) {
    console.error(err);
  }
}

export const selectSchemas = state => state.schemas.value;

export const selectLoading = state => state.schemas.loading;

export const selectCurrent = state => state.schemas.current;

function createKey(input) {
  return input ? input.replace(/^(the|a|an)/, '').replace(/\s/g, '') : input;
}

export default schemasSlice.reducer;
