import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import DamView from './features/dam/DamView';
// import SchemaEditor from './features/schemas/SchemaEditor';
// import SchemasList from './features/schemas/SchemasList';

function MainRouter() {

  return (
    <Router>
      <Switch>
        {/* <Route path="/schemas/:name">
          <SchemaEditor />
        </Route>
        <Route path="/schemas">
          <SchemasList />
        </Route> */}
        <Route path="/events">
          <DamView />
        </Route>
      </Switch>
    </Router>
  );
}

export default MainRouter;
