import React, { useEffect, useState } from 'react';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import ReactJson from 'react-json-view';

import ContentWrapper from '../../components/ContentWrapper';
import styles from './DamView.module.css';

const client = new W3CWebSocket(`${process.env.REACT_APP_WS_PROTOCOL}://${window.location.hostname}:443`);

function DamView() {

  const [notification, setNotification] = useState({});

  useEffect(() => {
    client.onopen = () => {
      console.log('WebSocket Client Connected');
    };
    client.onmessage = (message) => {
      const data = JSON.parse(message.data);
      setNotification(data);
    };
  }, []);

  return (
    <ContentWrapper>
      {Object.keys(notification).length ?
        <>
          <section>
            <div className={styles.row}>
              <div className={styles.label}>ID</div>
              <div className={styles.value}>{notification.id}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Name</div>
              <div className={styles.value}>{notification.name}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Description</div>
              <div className={styles.value}>{notification.description}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Dimensions</div>
              {notification.dimensions ?
                <div className={styles.value}>{notification.dimensions.width} W x {notification.dimensions.height} H</div>
                : <div className={styles.value}></div>
              }
            </div>
            <div className={styles.row}>
              <div className={styles.label}>URL</div>
              <div className={styles.value}>{notification.url}</div>
            </div>
          </section>
          <div style={{ marginTop: 20 }}>
            <ReactJson src={notification.full} collapsed={true} />
          </div>
        </>
        : <div className={styles.label}>No event</div>
      }
    </ContentWrapper>
  );
}

export default DamView;
