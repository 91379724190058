const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY);

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i++) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function sendSubscription(subscription) {
  // return fetch(`${process.env.REACT_APP_API_URL}/notifications/subscribe`, {
  // use proxy in package.json instead
  return fetch('/notifications/subscribe', {
    method: 'POST',
    body: JSON.stringify({
      subscription,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

// conditional render
let clicked = true;

export function subscribeUser() {
  console.log('Subscribing user...');
  if (clicked) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if (!registration.pushManager) {
          console.log('Push Manager unavailable.');
          return;
        }

        registration.pushManager.getSubscription().then((existingSubscription) => {
          if (existingSubscription === null) {
            console.log('No subscription detected. Make a request.');
            registration.pushManager.subscribe({
              applicationServerKey: convertedVapidKey,
              userVisibleOnly: true,
            })
            .then((newSubscription) => {
              console.log('New subscription added.', newSubscription);
              sendSubscription(newSubscription);
            })
            .catch((err) => {
              if (Notification.permission !== 'granted') {
                console.log('Permission not granted.');
              } else {
                console.error('An error occurred during the subscription process.', err);
              }
            })
          } else {
            console.log('Existing subscription detected.');
            sendSubscription(existingSubscription);
          }
        })
      })
      .catch((err) => {
        console.error('An error occurred during Service Worker registration.', err);
      });
  } else {
      console.log('Service Worker not reachable.');
    }
  }
}
