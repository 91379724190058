import React from 'react';
import GlobalNavigation from '@atlaskit/global-navigation';
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher';
import {
  GlobalItem,
  HeaderSection,
  Item,
  LayoutManager,
  MenuSection,
  NavigationProvider,
  UIControllerSubscriber,
} from '@atlaskit/navigation-next';
import { gridSize as gridSizeFn } from '@atlaskit/theme/constants';
import '@atlaskit/css-reset';
import styled from 'styled-components';

import EuropaLogo from './components/EuropaLogo';
import MainRouter from './MainRouter';

const gridSize = gridSizeFn();

const AppTitle = styled.div`
  padding-bottom: ${gridSize * 3.5 - 2}px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
`;

const AppSwitcherComponent = props => (
  <UIControllerSubscriber>
    {navigationUIController =>
      <GlobalItem
        {...props}
        icon={AppSwitcherIcon}
        id="switcher"
        onClick={navigationUIController.toggleCollapse}
      />
    }
  </UIControllerSubscriber>
);

const Global = () => (
  <GlobalNavigation
    productIcon={EuropaLogo}
    productTooltip="DAM Client"
    productHref="/"
    appSwitcherComponent={AppSwitcherComponent}
    appSwitcherTooltip="Switch to ..."
    loginHref="/logout"
  />
);

const Container = () => (
  <React.Fragment>
    <HeaderSection>
      {({ className }) =>
        <div className={className}>
          <AppTitle>DAM Client</AppTitle>
        </div>
      }
    </HeaderSection>
    <MenuSection>
      {({ className }) =>
        <div className={className}>
          {/* <Item text="Schemas" href="/schemas" /> */}
          <Item text="Events" href="/events" />
        </div>
      }
    </MenuSection>
  </React.Fragment>
);

function App() {
  return (
    <NavigationProvider>
      <LayoutManager
        globalNavigation={Global}
        productNavigation={() => null}
        containerNavigation={Container}
      >
        <MainRouter />
      </LayoutManager>
    </NavigationProvider>
  );
}

export default App;
