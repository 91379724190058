import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import schemasReducer from '../features/schemas/schemasSlice';

export default configureStore({
  reducer: {
    counter: counterReducer,
    schemas: schemasReducer,
  },
});
